import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux"; // Import dispatch

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getAdminGraphsCounts } from "../../../../redux/adminHistory/adminHistory.actions";

// Register the necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ActivePriceChart = ({ graphs }) => {
  const [fromDate, setFromDate] = useState(() => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);
    return lastMonth.toISOString().split("T")[0];
  });

  const [toDate, setToDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });

  const dispatch = useDispatch(); // Initialize dispatch function

  console.log(graphs, "graphs");

  // Dummy Data for the graph (you will replace this with actual API data)
  // const dummyGraphData = [
  //   {
  //     _id: { day: 1, month: 11, year: 2024 },
  //     gstCollected: 2000,
  //     platformFeeCollected: 1500,
  //     convenienceFeeCollected: 500,
  //   },
  //   {
  //     _id: { day: 5, month: 11, year: 2024 },
  //     gstCollected: 4000,
  //     platformFeeCollected: 2500,
  //     convenienceFeeCollected: 800,
  //   },
  //   {
  //     _id: { day: 10, month: 11, year: 2024 },
  //     gstCollected: 3000,
  //     platformFeeCollected: 2000,
  //     convenienceFeeCollected: 600,
  //   },
  //   {
  //     _id: { day: 15, month: 11, year: 2024 },
  //     gstCollected: 5000,
  //     platformFeeCollected: 3000,
  //     convenienceFeeCollected: 1000,
  //   },
  //   {
  //     _id: { day: 20, month: 11, year: 2024 },
  //     gstCollected: 3500,
  //     platformFeeCollected: 2500,
  //     convenienceFeeCollected: 700,
  //   },
  // ];

  const filterGraphData = () => {
    const start = new Date(fromDate);
    const end = new Date(toDate);

    return graphs.filter(({ _id }) => {
      const currentDate = new Date(`${_id.year}-${_id.month}-${_id.day}`);
      return currentDate >= start && currentDate <= end;
    });
  };

  const graphData = filterGraphData();

  const chartLabels = graphData.map(
    (data) => `${data._id.day}-${data._id.month}-${data._id.year}`
  );

  const gstData = graphData.map((data) => data.gstCollected || 0);
  const platformFeeData = graphData.map(
    (data) => data.platformFeeCollected || 0
  );
  const convenienceFeeData = graphData.map(
    (data) => data.convenienceFeeCollected || 0
  );

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        title: { display: true, text: "Dates" },
      },
      y: {
        title: { display: true, text: "Price (₹)" },
        beginAtZero: true,
        max: 10000, // Set Y-axis limit
      },
    },
    plugins: {
      legend: { display: true, position: "top" },
      tooltip: { enabled: true },
    },
  };

  const generateChartData = () => ({
    labels: chartLabels,
    datasets: [
      {
        label: "GST Collected",
        data: gstData,
        borderColor: "blue",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
      {
        label: "Platform Fee Collected",
        data: platformFeeData,
        borderColor: "green",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
      {
        label: "Convenience Fee Collected",
        data: convenienceFeeData,
        borderColor: "red",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
    ],
  });

  // UseEffect to dispatch the API call on mount
  // useEffect(() => {
  //   // Dispatch the API call on initial render
  //   dispatch(getAdminGraphsCounts()); // Assuming this API call is supposed to work on mount
  // }, [dispatch]); // Empty dependency array ensures it runs only once

  // Handle filter button click
  const handleFilterClick = () => {
    dispatch(getAdminGraphsCounts(fromDate, toDate));
  };

  return (
    <div className="p-4">
      <h5 style={{ color: "purple", fontWeight: "bold" }}>Price Collected</h5>
      <Form>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formStartDate">
              <Form.Label>From</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                max={toDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formEndDate">
              <Form.Label>To</Form.Label>
              <Form.Control
                type="date"
                value={toDate}
                min={fromDate}
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) => setToDate(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button type="button" variant="primary" onClick={handleFilterClick}>
          Filter
        </Button>
      </Form>

      {/* Combined Graph */}
      <div className="mt-4">
        <h6 style={{ color: "purple", fontWeight: "bold" }}>
          Price Collected (GST, Platform Fee & Convenience Fee)
        </h6>
        <Line data={generateChartData()} options={chartOptions} />
      </div>
    </div>
  );
};

export default ActivePriceChart;
