import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { arrowRight } from "../../../../../assets/svg/Svg";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Col, FormGroup, Modal, ModalBody, Row } from "react-bootstrap";
import { LuFilter } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  breedOfPetsCategoryDropdownList,
  createPetCategoryDropdown,
  deletePetCategoryDropdown,
  updatePetCategoryDropdown,
} from "../../../../../redux/dropdowns/userDropdown.action";
import { imageBaseUrl, uploadImage } from "../../../../../helper";
import { toast } from "react-toastify";

const PetCategories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    petImage: ""
  });
  const [modalShow, setModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteDropdownName, setDeleteDropdownName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { category, isStatusUpdate, loading } = useSelector(
    (state) => state.userDropdownInfo
  );
  const { profile } = useSelector((state) => state.authInfo);
  const prevProps = useRef({ isStatusUpdate }).current;

  useEffect(() => {
    dispatch(breedOfPetsCategoryDropdownList());
  }, []);

  useEffect(() => {
    if (prevProps.isStatusUpdate !== isStatusUpdate) {
      if (isStatusUpdate) {
        dispatch(breedOfPetsCategoryDropdownList());
      }
    }

    return () => {
      prevProps.isStatusUpdate = isStatusUpdate;
    };
  }, [isStatusUpdate]);

  useEffect(() => {
    if (category && category.length > 0) {
      setData(category);
    } else {
      setData([]);
    }
  }, [category]);

  const columns = [
    {
      name: "Image",
      selector: (row) => row.petImage ? <img src={`${imageBaseUrl}${row.petImage}`}></img> : '-',
      sortable: true,
    },
    {
      name: "Allergy Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) =>
        moment(new Date(row.createdAt)).format("DD-MM-YYYY h:mm a"),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex ">
          {(profile?.role == "admin" || profile?.dropdownsAccess?.canEdit) && (
            <FiEdit
              size={16}
              onClick={(e) => {
                setUpdateModalShow(true);
                setFormData(row);
                setSelectedId(row?._id);
              }}
            />
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {(profile?.role == "admin" ||
            profile?.dropdownsAccess?.canDelete) && (
              <AiOutlineDelete
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteId(row?._id);
                  setDeleteDropdownName(row?.name);
                }}
                size={19}
              />
            )}
        </div>
      ),
    },
  ];

  // const data = [
  //   {
  //     id: 0,
  //     allergy: "abc",
  //     email: "erw@gmail.com",
  //     createdAt: "01-01-2024",
  //     action: (
  //       <div className="d-flex ">
  //         <FiEdit size={16} />
  //         &nbsp;&nbsp;&nbsp;&nbsp;
  //         <AiOutlineDelete onClick={() => setDeleteModal(true)} size={19} />
  //       </div>
  //     ),
  //   },
  //   {
  //     id: 1,
  //     allergy: "xyz",
  //     email: "qwe@gmail.com",
  //     createdAt: "03-01-2024",
  //     action: (
  //       <div className="d-flex ">
  //         <FiEdit size={16} />
  //         &nbsp;&nbsp;&nbsp;&nbsp;
  //         <AiOutlineDelete onClick={() => setDeleteModal(true)} size={19} />
  //       </div>
  //     ),
  //   },
  // ];

  const filteredData = data.filter(
    (item) =>
      item?.name.toLowerCase().includes(searchText.toLowerCase()) &&
      (status ? item.status === status : true)
  );

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleStatusChange = (value) => {
    setStatus(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleSaveDropdown = async () => {
    if (!formData.name) {
      toast("Category name is required");
      return;
    }
    if (!formData.petImage) {
      toast("Please select image");
      return;
    }
    const imageUrl = await uploadImage('/pet/upload/pet/category/image/v1', formData.petImage.file, setSelectedImage);
    dispatch(createPetCategoryDropdown({ ...formData, petImage: imageUrl }, setModalShow, setFormData));
  };
  const handleUpdateDropdown = async () => {
    if (!formData.name) {
      toast("Category name is required");
      return;
    }
    if (formData.petImage) {
      const imageUrl = await uploadImage('/pet/upload/pet/category/image/v1', formData.petImage.file, setSelectedImage);
      dispatch(
        updatePetCategoryDropdown(
          selectedId,
          { ...formData, petImage: imageUrl },
          setUpdateModalShow,
          setFormData
        )
      );
    } else {
      dispatch(
        updatePetCategoryDropdown(
          selectedId,
          formData,
          setUpdateModalShow,
          setFormData
        )
      );
    }
  };

  const handleDelete = () => {
    dispatch(deletePetCategoryDropdown(deleteId, setDeleteModal, setFormData));
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="page-header">
              <h3 className="page-title">User</h3>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <>
                      {/* <button onClick={handleClearRows}>Clear Selected Rows</button> */}
                      <DataTable
                        // title={
                        //   <h6 className="card-title">
                        //     Allergy List
                        //   </h6>
                        // }
                        columns={columns}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3 w-100">
                              <Col md={12} className="d-flex gap-2">
                                <div className="d-flex align-items-start flex-column justify-content-end gap-2">
                                  <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                    <LuFilter />
                                    Filter By :
                                  </span>
                                  <div className="table_filter">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search by name"
                                      value={searchText}
                                      onChange={(e) => {
                                        setCurrentPage(1);
                                        handleSearch(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                {(profile?.role == "admin" ||
                                  profile?.dropdownsAccess?.canCreate) && (
                                    <div className="d-flex align-items-start flex-column justify-content-end ms-auto gap-2">
                                      <button
                                        className="btn_primary_outline_sm ms-auto"
                                        onClick={() => setModalShow(true)}
                                      >
                                        Add New Pet Category
                                      </button>
                                    </div>
                                  )}
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={modalShow}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Add New Pet Category</h5>
              <FormGroup className="mb-3">
                <label>Pet Image</label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .svg"
                  placeholder="Select page image"
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, petImage: { file: e.target.files[0] } })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Pet Category</label>
                <input
                  type="text"
                  placeholder="Pet Category"
                  value={formData.name}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => {
                  setModalShow(false);
                  setFormData({});
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleSaveDropdown}
              >
                Add
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={updateModalShow}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Update Pet Category</h5>
              <FormGroup className="mb-3">
                <label>Pet Image</label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .svg"
                  placeholder="Select image"
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, petImage: { file: e.target.files[0] } })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Pet Category</label>
                <input
                  type="text"
                  placeholder="Add Something..."
                  value={formData.name}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => {
                  setUpdateModalShow(false);
                  setSelectedId("");
                  setFormData({});
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleUpdateDropdown}
              >
                Update
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={deleteModal}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">{`Delete ${deleteDropdownName} Pet Category ?`}</h5>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => {
                  setDeleteModal(false);
                  setSelectedId("");
                  setDeleteId("");
                  setDeleteDropdownName("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleDelete}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default PetCategories;
