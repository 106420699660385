import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const GET_ADMIN_COUNTS_REQUEST = "GET_ADMIN_COUNTS_REQUEST";
export const GET_ADMIN_COUNTS_SUCCESS = "GET_ADMIN_COUNTS_SUCCESS";
export const GET_ADMIN_COUNTS_FAILURE = "GET_ADMIN_COUNTS_FAILURE";

export const GET_ADMIN_GRAPHS_REQUEST = "GET_ADMIN_GRAPHS_REQUEST";
export const GET_ADMIN_GRAPHS_SUCCESS = "GET_ADMIN_GRAPHS_SUCCESS";
export const GET_ADMIN_GRAPHS_FAILURE = "GET_ADMIN_GRAPHS_FAILURE";

export const getAdminHistoryCounts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ADMIN_COUNTS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/history/get/counts/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_ADMIN_COUNTS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_ADMIN_COUNTS_FAILURE, error);
    }
  };
};

export const getAdminGraphsCounts = (startDate, endDate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ADMIN_GRAPHS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/history/get/graphs/v1?startDate=${startDate}&endDate=${endDate}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_ADMIN_GRAPHS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_ADMIN_GRAPHS_FAILURE, error);
    }
  };
};
