import React, { useEffect, useRef, useState } from "react";
import { FormGroup } from "react-bootstrap";
import * as bannerActions from "../../../../redux/banners/banner.actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { imageBaseUrl, uploadImage } from "../../../../helper";
import { toast } from "react-toastify";

const user = [
  "Instant Consultation",
  "Schedule Consultation",
  "Homecare",
  "E-Health Card",
  "Government Pet",
  "Register Pet",
  "Blogs",
  "Videos",
  "Report abuse",
  "E-commerce",
  "Pharmacy",
  "Ambulance",
  "Reminders",
  "Refer & Earn",
  "Website",
];

const vendor = ["Add Product", "Website"];

const BannerDetail = () => {
  const [data, setData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [bannerMobileImage, setBannerMobileImage] = useState(null);
  const [webisteLink, setWebsiteLink] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const bgImageRef = useRef(null);
  const logoRef = useRef(null);
  const { profile } = useSelector((state) => state.authInfo);
  const loading = useSelector((state) => state.bannerInfo.loading);
  const details = useSelector((state) => state.bannerInfo.details);
  console.log("details", details);

  useEffect(() => {
    if (id) {
      dispatch(bannerActions.bannerDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && Object.keys(details).length > 0) {
      setData(details);
      if (details?.category?.startsWith("http")) {
        setWebsiteLink(data?.category);
      }
    } else {
      setData({});
    }
  }, [details]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      if (data?.category) {
        if (data.category?.startsWith("http")) {
          setWebsiteLink(data.category);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const isDataChanged = Object.keys(details).some(
      (key) => data[key] !== details[key]
    );
    if (isDataChanged) {
      (profile?.role == "admin" || profile?.bannersAccess?.canEdit) &&
        setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }

    if (backgroundImage) {
      (profile?.role == "admin" || profile?.bannersAccess?.canEdit) &&
        setIsUpdate(true);
    }

    if (bannerMobileImage) {
      (profile?.role == "admin" || profile?.bannersAccess?.canEdit) &&
        setIsUpdate(true);
    }
  }, [data, backgroundImage, bannerMobileImage]);

  const handleBgImageButtonClick = () => {
    bgImageRef.current.click();
  };

  const handleBannerMobileImageClick = () => {
    logoRef.current.click();
  };

  const handleUpdateBanner = async () => {
    if (!data.app || !data.category) {
      toast("Please fill fields");
      return;
    }
    let bgImage = "";
    let bgImageMobile = "";

    if (data.category === "Website") {
      if (!webisteLink) {
        toast("Please enter Website link");
        return;
      }
    }

    if (backgroundImage?.file) {
      bgImage = await uploadImage(
        "/admin/upload/banner/bg/image/v1",
        backgroundImage.file,
        setBackgroundImage
      );
    } else {
      bgImage = data?.bgImage;
    }

    if (bannerMobileImage?.file) {
      bgImageMobile = await uploadImage(
        "/admin/upload/banner/bg/image/v1",
        bannerMobileImage.file,
        setBackgroundImage
      );
    } else {
      bgImageMobile = data?.bgImageMobile;
    }

    // if (bannerMobileImage?.file) {
    //   bannerLogoImage = await uploadImage('/admin/upload/banner/bg/image/v1', bannerMobileImage.file, setBannerMobileImage);
    // } else {
    //   bannerLogoImage = data?.bannerLogoImage;
    // }

    // dispatch(bannerActions.updateBanner(id, { ...data, bgImage, bannerLogoImage }));
    dispatch(bannerActions.updateBanner(id, {
      ...data, bgImage, bgImageMobile, category:
        data.category === "Website" ? webisteLink : data.category,
    }));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {data && Object.keys(data).length > 0 ? (
            <div>
              <div className="page-header">
                <h3 className="page-title">Banner Detail</h3>
              </div>
              <div className="container-fluid">
                {
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          {backgroundImage?.file ? (
                            <>
                              <label>Banner image</label>
                              <br />
                              <img
                                src={URL.createObjectURL(backgroundImage.file)}
                                style={{ width: "200px", height: "200px" }}
                              />
                            </>
                          ) : (
                            <>
                              {data?.bgImage && (
                                <>
                                  <label>Banner image</label>
                                  <br />
                                  <img
                                    src={`${imageBaseUrl}${data?.bgImage}`}
                                    style={{ width: "200px", height: "200px" }}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {(profile?.role == "admin" ||
                            profile?.bannersAccess?.canEdit) && (
                              <button
                                className="btn_primary_outline_sm mb-3 mt-3"
                                onClick={handleBgImageButtonClick}
                              >
                                Change image
                              </button>
                            )}
                          <input
                            type="file"
                            ref={bgImageRef}
                            accept="image/*"
                            onChange={(e) =>
                              setBackgroundImage({ file: e.target.files[0] })
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                        <div className="col-6">
                          {bannerMobileImage?.file ? (
                            <>
                              <label>Banner Mobile Image</label>
                              <br />
                              <img
                                src={URL.createObjectURL(bannerMobileImage.file)}
                                style={{ width: "200px", height: "200px" }}
                              />
                            </>
                          ) : (
                            <>
                              {
                                data?.bgImageMobile &&
                                <>
                                  <label>Banner Mobile Image</label>
                                  <br />
                                  <img src={`${imageBaseUrl}${data?.bgImageMobile}`} style={{ width: "200px", height: "200px" }} />
                                </>
                              }
                            </>
                          )}
                          <button className="btn_primary_outline_sm mb-3 mt-3" onClick={handleBannerMobileImageClick}>Change Mobile Banner</button>
                          <input
                            type="file"
                            ref={logoRef}
                            accept="image/*"
                            onChange={e => setBannerMobileImage({ file: e.target.files[0] })}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Button name</label>
                            <input
                              type="text"
                              placeholder="Button name"
                              value={data.buttonName}
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, buttonName: e.target.value })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>App</label>
                            <select
                              type="text"
                              placeholder="App"
                              value={data.app}
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, app: e.target.value })
                              }
                            >
                              <option selected value="">
                                Select App
                              </option>
                              <option value="user">user</option>
                              <option value="vendor">vendor</option>
                            </select>
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Category</label>
                            <select
                              type="text"
                              placeholder="Category"
                              value={data?.category?.startsWith("http") ? "Website" : data.category}
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, category: e.target.value })
                              }
                            >
                              <option selected value="">
                                Select Category
                              </option>
                              {data.app === "user" &&
                                user.map((elem) => (
                                  <option value={elem} key={elem}>
                                    {elem}
                                  </option>
                                ))}
                              {data.app === "vendor" &&
                                vendor.map((elem) => (
                                  <option value={elem} key={elem}>
                                    {elem}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Heading</label>
                            <input
                              type="text"
                              placeholder="Heading"
                              value={data.headName}
                              className="form-control"
                              onChange={(e) =>
                                setData({ ...data, headName: e.target.value })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className="col-6">
                          <FormGroup className="mb-3">
                            <label>Description</label>
                            <textarea
                              className="form-control"
                              placeholder="Description"
                              value={data.paragraph}
                              rows="4"
                              onChange={(e) =>
                                setData({ ...data, paragraph: e.target.value })
                              }
                            ></textarea>
                          </FormGroup>
                        </div>
                        {data.category === "Website" || data?.category?.startsWith("http") && (
                          <div className="col-6">
                            <FormGroup className="mb-3">
                              <label>Webiste link</label>
                              <input
                                type="text"
                                placeholder="Website link"
                                value={webisteLink}
                                className="form-control"
                                onChange={(e) => setWebsiteLink(e.target.value)}
                                required={true}
                              />
                            </FormGroup>
                          </div>
                        )}
                      </div>
                      {isUpdate && (
                        <button
                          className="btn_primary_outline_sm"
                          disabled={loading}
                          onClick={(e) => handleUpdateBanner()}
                        >
                          {loading ? (
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                          ) : (
                            "UPDATE"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          ) : (
            <h2 className="text-center mt-3 text-muted">No Data Found</h2>
          )}
        </>
      )}
    </>
  );
};

export default BannerDetail;
