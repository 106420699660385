import * as adminHistory from "./adminHistory.actions";

export const adminHistoryFeatureKey = "adminHistoryInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  counts: {},
  graphs: [],
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case adminHistory.GET_ADMIN_COUNTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminHistory.GET_ADMIN_COUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        counts: payload.data,
      };

    case adminHistory.GET_ADMIN_COUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case adminHistory.GET_ADMIN_GRAPHS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminHistory.GET_ADMIN_GRAPHS_SUCCESS:
      return {
        ...state,
        loading: false,
        graphs: payload.data,
      };

    case adminHistory.GET_ADMIN_GRAPHS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
